import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
// react library for routing
import { DndProvider } from 'react-dnd';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
// bootstrap rtl for rtl support page
import 'assets/vendor/bootstrap-rtl/bootstrap-rtl.scss';
// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// plugins styles downloaded
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';

// core styles
import 'antd/dist/antd.css';
import 'assets/scss/argon-dashboard-pro-react.scss?v1.1.0';
import 'index.css';
////redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './rootStore';

import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import RTLLayout from 'layouts/RTL.js';
import LiveWorkSheetReview from 'pages/LiveWorkSheetReview';
import ViewResultExercise from 'views/pages/Result/ViewResultExercise';
import Page404 from 'pages/Page404';
import Login from 'modules/LoginAmes_AiModule/components/LoginComponent';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';

const AuthenticateWrapper = ({ childProps, component }) => {
  // const { pathname, search } = childProps.location;
  // const loggedInUser = useSelector((state) => state.teacherReducer.loggedInUser ?? state.loginReducer.loggedInUser);
  // let to = pathname + search;

  return (
    <React.Fragment>
      {/* <Redirect to={to} /> */}
      {component}
    </React.Fragment>
  );
};

AuthenticateWrapper.propTypes = {
  component: PropTypes.node.isRequired,
  childProps: PropTypes.instanceOf(Object).isRequired,
};

// Create a query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const isOriginRoute = window.location.pathname === '/';
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const Backend = isMobile ? TouchBackend : HTML5Backend;
  React.useEffect(() => {
    const isMobile = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };
    if (!isMobile()) {
      document.body.classList.replace('g-sidenav-hidden', 'g-sidenav-pinned');
    } else {
      document.body.classList.replace('g-sidenav-pinned', 'g-sidenav-hidden');
    }
  }, []);
  return (
    <DndProvider backend={Backend}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <Switch>
                {/* Route xem kết quả type worksheet */}
                <Route
                  path="/view/class/:classId/session/:sessionId/results/:logId/type/:excerciseType"
                  component={ViewResultExercise}
                />
                <Route path="/preview/:id" component={LiveWorkSheetReview} />
                <Route path="/ames" component={AdminLayout} />
                <Route path="/rtl" component={RTLLayout} />
                <Route path="/auth" component={AuthLayout} />
                <Route path="/login" component={Login} />
                <Route path="/404-page" component={Page404} />
                <Redirect from="*" to={isOriginRoute ? '/auth' : '/404-page'} />
              </Switch>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </DndProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
