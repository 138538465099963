import StudentChoiceAccountModal from 'components/Modal/StudentChoiceAccount';
import * as ActionType from 'modules/LoginAmes_AiModule/actions/types';
const { useState, useEffect, useCallback } = require('react');
const { useSelector, useDispatch } = require('react-redux');

const MultipleAccountSelect = () => {
  const [state, setState] = useState({
    isVisibled: false,
    users: [],
  });
  const loggedInUser = useSelector((rootState) => {
    return rootState.loginReducer.loggedInUser;
  });

  const dispatch = useDispatch();
  // Kiểm tra tài khoản login có bao nhiêu người dùng
  useEffect(() => {
    const isSetItem = localStorage.getItem('loggedInUser');
    if (isSetItem) return;
    if (loggedInUser.typeLogin.includes('ames')) {
      if (loggedInUser.userMyames.length > 1) {
        setState((previousState) => ({
          ...previousState,
          isVisibled: !state.isVisibled,
          users: loggedInUser.userMyames,
        }));
      } else {
        const userMyamesNew = loggedInUser.userMyames[0];
        const userInfo = { ...loggedInUser, userMyames: userMyamesNew, allUserMyames: loggedInUser.userMyames };
        localStorage.setItem('loggedInUser', JSON.stringify(userInfo));
        dispatch({ type: ActionType.POST_LOGIN_SUCCESS, payload: userInfo });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const toggleModal = useCallback(() => {
    setState((previousState) => ({ ...previousState, isVisibled: !previousState.isVisibled }));
  }, []);

  // Lưu tài khoản mới
  const saveAccount = (index) => {
    const userMyamesNew = loggedInUser.userMyames[index];
    const userInfo = { ...loggedInUser, userMyames: userMyamesNew, allUserMyames: loggedInUser.userMyames };

    localStorage.setItem('loggedInUser', JSON.stringify(userInfo));
    setState((previousState) => ({ ...previousState, isVisibled: !state.isVisibled }));
    dispatch({ type: ActionType.POST_LOGIN_SUCCESS, payload: userInfo });
  };

  return (
    <StudentChoiceAccountModal
      users={state.users}
      toggleModal={toggleModal}
      saveAccount={saveAccount}
      isVisibled={state.isVisibled}
    />
  );
};

export default MultipleAccountSelect;
